import { h } from 'preact';
import { Hoverable } from './Hoverable';
import { openExternalLink } from './openExternalLink';
export default function InlineButton(props) {
    return (h(Hoverable, { floatRight: props.floatRight },
        h("button", { class: "btn-link primer-spec-hoverable no-print", onClick: props.onClick
                ? (event) => {
                    event.preventDefault();
                    props.onClick && props.onClick();
                }
                : undefined, "aria-label": props.ariaLabel },
            h("i", { class: props.icon }))));
}
export function InlineLinkButton(props) {
    return (h(Hoverable, { floatRight: props.floatRight },
        h("a", { class: "btn-link primer-spec-hoverable no-print tooltipped tooltipped-no-delay tooltipped-w", role: "button", href: props.href, onClick: (event) => {
                event.preventDefault();
                openExternalLink({ url: props.href, download: props.download });
            }, "aria-label": props.ariaLabel },
            h("i", { class: props.icon }))));
}
