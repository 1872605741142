import { h } from 'preact';
import clsx from 'clsx';
import Config from '../../Config';
import { Subthemes, updateTheme, normalizeSubthemeMode } from '../../subthemes';
import { useAfterPrint, useBeforePrint, usePrintInProgress, } from '../../utils/hooks/print';
import usePrefersDarkMode from '../../utils/hooks/usePrefersDarkMode';
import ThemePreview from './ThemePreview';
const SUBTHEME_MODE_INFO = [
    { name: 'light', label: 'Light' },
    { name: 'dark', label: 'Dark' },
    { name: 'system', label: 'Sync with OS setting' },
];
export default function Settings(props) {
    const is_print_in_progress = usePrintInProgress();
    // Refresh the component if dark mode changes (because we also want to
    // refresh the theme previews).
    usePrefersDarkMode();
    // If a print is in progress, temporarily reset the theme to default light.
    useBeforePrint(() => updateTheme({ name: 'default', mode: 'light' }, false));
    useAfterPrint(() => updateTheme({ name: props.currentSubthemeName, mode: props.currentSubthemeMode }, false));
    if (!props.settingsShown || is_print_in_progress) {
        return null;
    }
    const normalizedMode = normalizeSubthemeMode(props.currentSubthemeMode);
    return (h("div", { class: "primer-spec-settings-container position-fixed top-0 left-0 width-full height-full" },
        h("div", { class: clsx('primer-spec-settings', 'container-lg', 'markdown-body', 'px-3', 'my-5', {
                'primer-spec-content-margin-extra': props.sidebarShown && !props.isSmallScreen,
                'primer-spec-content-mobile': props.isSmallScreen,
            }) },
            h("h1", { class: "primer-spec-toc-ignore" }, 'Spec Theme Settings'),
            h("h2", null, "Colors"),
            h("form", { class: "primer-spec-settings-theme-preview-container", onSubmit: (e) => e.preventDefault() }, Object.values(Subthemes).map((subtheme) => (h("label", { key: subtheme.name, class: clsx('primer-spec-settings-theme-preview-box', {
                    'primer-spec-settings-theme-preview-selected': props.currentSubthemeName === subtheme.name,
                }) },
                h(ThemePreview, { subtheme: subtheme, mode: normalizedMode }),
                h("div", { class: clsx('primer-spec-settings-theme-preview-title', {
                        'primer-spec-settings-theme-preview-selected': props.currentSubthemeName === subtheme.name,
                    }) },
                    h("input", { type: "radio", value: subtheme.name, name: "primer-spec-settings-subtheme", checked: props.currentSubthemeName === subtheme.name, onChange: (e) => props.onSubthemeNameChange(e.target.value) }),
                    ' ',
                    subtheme.label))))),
            h("h2", null, "Color mode"),
            h("form", { onSubmit: (e) => e.preventDefault() }, SUBTHEME_MODE_INFO.map((modeInfo) => (h("label", { key: modeInfo.name, class: "primer-spec-settings-theme-mode-label" },
                h("input", { type: "radio", value: modeInfo.name, name: "primer-spec-settings-subtheme-mode", checked: props.currentSubthemeMode === modeInfo.name, onChange: (e) => props.onSubthemeModeChange(e.target
                        .value) }),
                ' ',
                modeInfo.label)))),
            h("hr", null),
            h("p", null,
                h("small", null,
                    'Does the spec display incorrectly? ',
                    h("a", { href: "https://github.com/eecs485staff/primer-spec/issues" }, 'Let us know by adding a new "issue" here.'))),
            h("p", { class: "primer-spec-brand" },
                h("a", { href: "https://github.com/eecs485staff/primer-spec/", target: "_blank", rel: "noreferrer" }, `Primer Spec v${Config.VERSION_RAW}`)))));
}
