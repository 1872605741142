import { h, Fragment } from 'preact';
import Config from '../../Config';
import getSitemapName from './getSitemapName';
import IconType from '../common/IconType';
export default function SidebarContent(props) {
    if (props.sitemap == null) {
        return (h("div", { role: "presentation", onClick: () => true }, props.children));
    }
    const isRootPageCurrent = props.sitemap.rootPage.current;
    return (h(Fragment, null,
        h(Sitemap, { sitemap: props.sitemap }, isRootPageCurrent ? undefined : props.children),
        h("hr", null),
        h(SitemapPage, { page: props.sitemap.rootPage, dedent: true }, isRootPageCurrent ? props.children : undefined)));
}
function Sitemap(props) {
    const shouldDedentSitemap = Config.SITEMAP_LABEL == null;
    const renderedSiteUrls = (h(Fragment, null, props.sitemap.siteUrls.map((sitePage) => (h(SitemapPage, { key: sitePage.url, page: sitePage, dedent: shouldDedentSitemap }, sitePage.current ? props.children : undefined)))));
    if (shouldDedentSitemap) {
        return renderedSiteUrls;
    }
    return (h("details", { role: "navigation", "aria-label": Config.SITEMAP_LABEL ?? 'Sitemap', open: props.sitemap.rootPage.current ? undefined : true },
        h("summary", null, Config.SITEMAP_LABEL),
        renderedSiteUrls));
}
function SitemapPage(props) {
    const title = props.page.title || (props.page.path && getSitemapName(props.page.path));
    if (!title) {
        console.error(`Primer Spec: Page with URL ${props.page.url} has no title to display in sidebar`);
    }
    if (props.page.current) {
        return (h("details", { open: true, class: props.dedent ? 'left-space-fix' : 'primer-spec-toc-sitemap-item' },
            h("summary", { class: "left-space-fix primer-spec-toc-active" }, title),
            props.children));
    }
    // Wrap everything in an <a/> element to indicate to mouse-users that it's
    // a hyperlink. But don't make it focusable because the <summary/> element
    // is itself focusable. Let the <summary/> function as the true hyperlink
    // element for keyboard users and screen-readers.
    return (h("a", { href: props.page.url, tabIndex: -1 },
        h("details", { class: props.dedent ? '' : 'primer-spec-toc-sitemap-item' },
            h("summary", { class: props.page.external ? 'primer-spec-toc-sitemap-external' : '', "data-order": props.page.external ? '' : props.page.sitemapOrder ?? '', role: "link", tabIndex: 0, onClick: (e) => {
                    e.preventDefault();
                    window.location.href = props.page.url;
                } },
                title,
                props.page.external && (h(Fragment, null,
                    h("i", { class: IconType.EXTERNAL_LINK }),
                    h("span", { class: "sr-only" }, "External Link")))))));
}
