import createSubtheme from './createSubtheme';
import default_theme from './definitions/default.theme';
import bella_theme from './definitions/bella.theme';
import modern_theme from './definitions/modern.theme';
import xcode_civic_theme from './definitions/xcode_civic.theme';
export const REGISTERED_SUBTHEMES = {
    default: createSubtheme('default', 'Primer', default_theme),
    bella: createSubtheme('bella', 'Bella', bella_theme),
    modern: createSubtheme('modern', 'Modern', modern_theme),
    'xcode-civic': createSubtheme('xcode-civic', 'Xcode Civic', xcode_civic_theme),
};
export function registerNewSubtheme(name, label, definition) {
    REGISTERED_SUBTHEMES[name] = createSubtheme(name, label, definition);
}
window.PrimerSpec = {
    ...window.PrimerSpec,
    REGISTERED_SUBTHEMES,
    registerNewSubtheme,
};
