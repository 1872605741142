import Config from '../../Config';
/**
 * Parse raw sitemap information from `Config.ts` and return a data structure
 * that is easier to render.
 *
 * NOTE: The result of this method is not memoized.
 * @param sitemapEnabled boolean indicating whether to use sitemap URLs from
 *                       `Config.ts`
 */
export default function getSitemapUrls(sitemapEnabled) {
    if (!sitemapEnabled) {
        return null;
    }
    // Remove assets pages
    const siteUrls = Config.SITEMAP_URLS.filter((pageInfo) => !pageInfo.path?.startsWith('assets'));
    if (siteUrls.length === 0) {
        return null;
    }
    const rootIndex = siteUrls.findIndex((page) => /^(index|readme)\.(md|htm|html)$/.test(page.path?.toLowerCase() || ''));
    if (rootIndex === -1) {
        console.warn('PrimerSpec: processSitemapURLs: Expected to find a root page. Disabling sitemap.');
        return null;
    }
    const [rootPage] = siteUrls.splice(rootIndex, 1);
    rootPage.title = Config.SITEMAP_SITE_TITLE;
    siteUrls.sort((lhs, rhs) => {
        if (lhs.external && rhs.external) {
            // If both links are external, preserve the order that they are declared
            // in.
            return 0;
        }
        else if (lhs.external) {
            // If lhs is external, it should come after all internal links.
            return 1;
        }
        else if (rhs.external) {
            // If rhs is external but not lhs, then rhs should come after lhs.
            return -1;
        }
        const lhsOrder = lhs.sitemapOrder ?? Number.MAX_VALUE;
        const rhsOrder = rhs.sitemapOrder ?? Number.MAX_VALUE;
        // If both items are internal, sort them based on which one's sitemapOrder
        // property is lower.
        return lhsOrder - rhsOrder;
    });
    return { rootPage, siteUrls };
}
