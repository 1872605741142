import { h } from 'preact';
import { useLayoutEffect, useRef } from 'preact/hooks';
import clsx from 'clsx';
import IconType from './common/IconType';
import InlineButton, { InlineLinkButton } from './common/InlineButton';
import Config from '../Config';
// NOTE: If you change the class names or IDs in this file, don't forget to
// also fix the Conditional Plugin jokes like Halloween and April Fools!
const SETTINGS_TOGGLE_ID = 'primer-spec-settings-toggle';
const TOPBAR_BUTTON_CLASSNAME = 'primer-spec-topbar-button';
export default function Topbar(props) {
    const topbarRef = useRef(null);
    const { isSmallScreen, onActiveSectionOffsetChange } = props;
    useLayoutEffect(() => {
        if (!isSmallScreen) {
            // On wide screens, the Topbar is transparent, so its effective height is
            // 0.
            onActiveSectionOffsetChange(20);
        }
        else if (topbarRef.current) {
            // On small screens, the Topbar is opaque, so we should find its true
            // height.
            onActiveSectionOffsetChange(topbarRef.current.getBoundingClientRect().height);
        }
    }, [isSmallScreen, onActiveSectionOffsetChange]);
    let sidebar_toggle = null;
    if (props.showSidebarToggle) {
        sidebar_toggle = props.sidebarShown ? null : (h("div", { class: `primer-spec-topbar-button primer-spec-float-left` },
            h(InlineButton, { icon: IconType.SIDEBAR, onClick: props.onToggleSidebar, ariaLabel: 'Open navigation pane' })));
    }
    let downloadPdfButton = null;
    if (Config.PDF_PATH != null &&
        (!props.isSmallScreen || (props.isSmallScreen && props.settingsShown))) {
        const href = Config.SITE_QUALIFIED_BASE_URL + Config.PDF_PATH;
        downloadPdfButton = (h("div", { class: TOPBAR_BUTTON_CLASSNAME },
            h(InlineLinkButton, { icon: IconType.DOWNLOAD, href: href, download: true, ariaLabel: "Download this page as a PDF file" })));
    }
    let settings_toggle = null;
    if (props.showSettingsToggle) {
        settings_toggle = (h("div", { id: SETTINGS_TOGGLE_ID, class: TOPBAR_BUTTON_CLASSNAME },
            h(InlineButton, { icon: props.settingsShown ? IconType.CLOSE : IconType.SETTINGS, onClick: props.onToggleSettings, ariaLabel: props.settingsShown ? 'Close settings pane' : 'Open settings pane' })));
    }
    return (h("header", { ref: topbarRef, class: clsx('primer-spec-topbar', 'position-fixed', 'width-full', 'top-0', 'left-0', 'py-2', 'no-print', {
            'primer-spec-topbar-mobile': props.isSmallScreen,
            'primer-spec-topbar-settings-shown': props.settingsShown,
        }) },
        sidebar_toggle,
        h("div", { class: "primer-spec-float-right" },
            downloadPdfButton,
            settings_toggle)));
}
