import { h } from 'preact';
import { Hoverable } from './Hoverable';
export default function InlineButton(props) {
    return (h(Hoverable, { floatRight: props.floatRight },
        h("a", { href: props.href ?? '#primer-spec-top', class: "primer-spec-hoverable no-print", onClick: props.onClick
                ? (event) => {
                    event.preventDefault();
                    props.onClick && props.onClick();
                }
                : undefined, "aria-label": props.ariaLabel },
            h("i", { class: props.icon }))));
}
