export const SUBTHEME_VARS = [
    '--sidebar-bg-color',
    '--sidebar-border-color',
    '--sidebar-heading-text-color',
    '--sidebar-heading-link-color',
    '--sidebar-toc-h1-link-color',
    '--sidebar-toc-h1-border-color',
    '--sidebar-toc-h2-link-color',
    '--sidebar-active-toc-section-bg-color',
    '--sidebar-active-toc-section-link-color',
    '--sidebar-active-toc-h1-section-link-color',
    '--sidebar-active-toc-h2-section-link-color',
    '--sidebar-toc-section-link-color',
    '--sidebar-tt-active-text-color',
    '--sidebar-tt-active-bg-color',
    '--sidebar-tt-active-border',
    '--sidebar-tt-active-border-radius',
    '--sidebar-resize-handle',
    '--main-heading-text-color',
    '--main-heading-link-color',
    '--main-link-color',
    '--hoverable-link-hover-color',
    '--main-header-border-bottom-color',
    '--main-image-border-color',
    '--main-image-border-color-inverted',
    '--tt-text-color',
    '--tt-bg-color',
    '--tt-border',
    '--tt-border-radius',
    '--main-bg-color',
    '--main-text-color',
    '--main-table-bg-color',
    '--main-table-bg-contrast-color',
    '--main-table-pre-bg-color',
    '--main-table-pre-text-color',
    '--main-table-pre-border',
    '--main-blockquote-text-color',
    '--main-blockquote-text-border',
    '--main-header-border-bottom-color',
    '--primer-spec-callout-neutral-bg-color',
    '--primer-spec-callout-neutral-text-color',
    '--primer-spec-callout-neutral-border-color',
    '--primer-spec-callout-info-bg-color',
    '--primer-spec-callout-info-text-color',
    '--primer-spec-callout-info-border-color',
    '--primer-spec-callout-info-icon-color',
    '--primer-spec-callout-warning-bg-color',
    '--primer-spec-callout-warning-text-color',
    '--primer-spec-callout-warning-border-color',
    '--primer-spec-callout-warning-icon-color',
    '--primer-spec-callout-danger-bg-color',
    '--primer-spec-callout-danger-text-color',
    '--primer-spec-callout-danger-border-color',
    '--primer-spec-callout-danger-icon-color',
    '--primer-spec-callout-success-bg-color',
    '--primer-spec-callout-success-text-color',
    '--primer-spec-callout-success-border-color',
    '--primer-spec-callout-success-icon-color',
    '--code-block-default-color',
    '--code-block-border-color',
    '--code-block-header-bg-color',
    '--code-block-bg-color',
    '--code-block-line-number-color',
    '--code-block-highlight-bg-color',
    '--code-block-highlight-shadow-color',
    '--code-block-button-color',
    '--code-block-button-bg-color',
    '--code-block-button-border-color',
    '--code-block-button-shadow',
    '--code-block-button-shadow-inset-color',
    '--code-block-button-hover-bg-color',
    '--code-block-button-hover-border-color',
    '--tooltip-background-color',
    '--tooltip-color',
    '--kbd-border-color',
];
export const ROUGE_STYLE_PROPS = [
    'color',
    'background-color',
    'font-style',
    'font-weight',
];
export const ROUGE_CLASS_NAMES = [
    'cm',
    'cp',
    'c1',
    'cs',
    'cd',
    'c',
    'err',
    'gd',
    'ge',
    'gr',
    'gh',
    'gi',
    'go',
    'gp',
    'gs',
    'gu',
    'gt',
    'kc',
    'kd',
    'kn',
    'kp',
    'kr',
    'kt',
    'kv',
    'k',
    'mf',
    'mh',
    'il',
    'mi',
    'mo',
    'mb',
    'mx',
    'm',
    'sb',
    'sc',
    'sd',
    's2',
    'se',
    'sh',
    'si',
    'sx',
    'sr',
    's1',
    'ss',
    's',
    'na',
    'bp',
    'nb',
    'nc',
    'no',
    'nd',
    'ni',
    'ne',
    'nf',
    'nl',
    'nn',
    'nt',
    'nx',
    'vc',
    'vg',
    'vi',
    'nv',
    'ow',
    'o',
    'w',
    'txt', // Text
];
