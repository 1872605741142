var IconType;
(function (IconType) {
    IconType["CLOSE"] = "fas fa-times";
    IconType["HOME"] = "fas fa-home";
    IconType["SETTINGS"] = "fas fa-cog";
    IconType["SIDEBAR"] = "fas fa-bars";
    IconType["EXTERNAL_LINK"] = "fas fa-external-link-alt";
    IconType["DOWNLOAD"] = "fas fa-file-download";
})(IconType || (IconType = {}));
export default IconType;
