const default_rouge_style = {
    cm: {
        // Comment::Multiline
        color: '#999988',
        'font-style': 'italic',
    },
    cp: {
        // Comment::Preproc
        color: '#999999',
        'font-weight': 'bold',
    },
    c1: {
        // Comment::Single
        color: '#999988',
        'font-style': 'italic',
    },
    cs: {
        // Comment::Special
        color: '#999999',
        'font-weight': 'bold',
        'font-style': 'italic',
    },
    cd: {
        // Comment
        color: '#999988',
        'font-style': 'italic',
    },
    c: {
        // Comment
        color: '#999988',
        'font-style': 'italic',
    },
    err: {
        // Error
        color: '#a61717',
        'background-color': '#e3d2d2',
    },
    gd: {
        // Generic::Deleted
        color: '#000000',
        'background-color': '#ffdddd',
    },
    ge: {
        // Generic::Emph
        color: '#000000',
        'font-style': 'italic',
    },
    gr: {
        // Generic::Error
        color: '#aa0000',
    },
    gh: {
        // Generic::Heading
        color: '#999999',
    },
    gi: {
        // Generic::Inserted
        color: '#000000',
        'background-color': '#ddffdd',
    },
    go: {
        // Generic::Output
        color: '#888888',
    },
    gp: {
        // Generic::Prompt
        color: '#555555',
    },
    gs: {
        // Generic::Strong
        'font-weight': 'bold',
    },
    gu: {
        // Generic::Subheading
        color: '#aaaaaa',
    },
    gt: {
        // Generic::Traceback
        color: '#aa0000',
    },
    kc: {
        // Keyword::Constant
        color: '#000000',
        'font-weight': 'bold',
    },
    kd: {
        // Keyword::Declaration
        color: '#000000',
        'font-weight': ' bold',
    },
    kn: {
        // Keyword::Namespace
        color: '#000000',
        'font-weight': 'bold',
    },
    kp: {
        // Keyword::Pseudo
        color: '#000000',
        'font-weight': 'bold',
    },
    kr: {
        // Keyword::Reserved
        color: '#000000',
        'font-weight': 'bold',
    },
    kt: {
        // Keyword::Type
        color: '#445588',
        'font-weight': 'bold',
    },
    kv: {
        // Keyword
        color: '#000000',
        'font-weight': 'bold',
    },
    k: {
        // Keyword
        color: '#000000',
        'font-weight': 'bold',
    },
    mf: {
        // Literal::Number::Float
        color: '#009999',
    },
    mh: {
        // Literal::Number::Hex
        color: '#009999',
    },
    il: {
        // Literal::Number::Integer::Long
        color: '#009999',
    },
    mi: {
        // Literal::Number::Integer
        color: '#009999',
    },
    mo: {
        // Literal::Number::Oct
        color: '#009999',
    },
    mb: {
        // Literal::Number
        color: '#009999',
    },
    mx: {
        // Literal::Number
        color: '#009999',
    },
    m: {
        // Literal::Number
        color: '#009999',
    },
    sb: {
        // Literal::String::Backtick
        color: '#d14',
    },
    sc: {
        // Literal::String::Char
        color: '#d14',
    },
    sd: {
        // Literal::String::Symbol
        color: '#d14',
    },
    s2: {
        // Literal::String::Double
        color: '#d14',
    },
    se: {
        // Literal::String::Escape
        color: '#d14',
    },
    sh: {
        // Literal::String::Heredoc
        color: '#d14',
    },
    si: {
        // Literal::String::Interpol
        color: '#d14',
    },
    sx: {
        // Literal::String::Other
        color: '#d14',
    },
    sr: {
        // Literal::String::Regex
        color: '#009926',
    },
    s1: {
        // Literal::String::Single
        color: '#d14',
    },
    ss: {
        // Literal::String::Symbol
        color: '#990073',
    },
    s: {
        // Literal::String
        color: '#d14',
    },
    na: {
        // Name::Attribute
        color: '#008080',
    },
    bp: {
        // Name::Builtin::Pseudo
        color: '#999999',
    },
    nb: {
        // Name::Builtin
        color: '#0086B3',
    },
    nc: {
        // Name::Class
        color: '#445588',
        'font-weight': 'bold',
    },
    no: {
        // Name::Constant
        color: '#008080',
    },
    nd: {
        // Name::Decorator
        color: '#3c5d5d',
        'font-weight': 'bold',
    },
    ni: {
        // Name::Entity
        color: '#800080',
    },
    ne: {
        // Name::Exception
        color: '#990000',
        'font-weight': 'bold',
    },
    nf: {
        // Name::Function
        color: '#990000',
        'font-weight': 'bold',
    },
    nl: {
        // Name::Label
        color: '#990000',
        'font-weight': 'bold',
    },
    nn: {
        // Name::Namespace
        color: '#555555',
    },
    nt: {
        // Name::Tag
        color: '#000080',
    },
    nx: {
        // Name::Other
        color: '#000000',
    },
    vc: {
        // Name::Variable::Class
        color: '#008080',
    },
    vg: {
        // Name::Variable::Global
        color: '#008080',
    },
    vi: {
        // Name::Variable::Instance
        color: '#008080',
    },
    nv: {
        // Name::Variable::Instance
        color: '#008080',
    },
    ow: {
        // Operator::Word
        color: '#000000',
        'font-weight': 'bold',
    },
    o: {
        // Operator
        color: '#000000',
        'font-weight': 'bold',
    },
    w: {
        // Text::Whitespace
        color: '#bbbbbb',
    },
    txt: {
        'background-color': '#f8f8f8',
    },
};
export default default_rouge_style;
